<template>
  <div class="hero">
    <div class="hero__inner">
      <img src="@/assets/logo-inverted.svg" class="d-none d-md-block hero__logo" />
      <h1 class="hero__text">{{ $t('home.hero.text') }}</h1>
      <div class="hero__actions d-flex flex-column align-items-center d-sm-block">
        <router-link
          class="hero__btn btn btn-inline-sm btn-sm-lg btn-primary mt-3"
          :to="{ name: 'Login' }"
          >{{ $t('home.hero.sign_up') }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss">
@keyframes blur {
  from {
    filter: blur(0);
  }

  to {
    filter: blur(2px);
  }
}

@keyframes blurlg {
  from {
    filter: blur(0);
  }

  to {
    filter: blur(2.5px);
  }
}

@keyframes blurxl {
  from {
    filter: blur(0);
  }

  to {
    filter: blur(3.5px);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.hero {
  position: relative;
  overflow: hidden;
  &::before {
    will-change: filter;
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background-position: top center;
    background-size: cover;
    z-index: 0;
    background-image: url('~@/assets/hero/hero-blurred-sm.jpg');

    @include lg-up {
      background-image: url('~@/assets/hero/hero-blurred-md.jpg');
    }

    @include xl-up {
      background-image: url('~@/assets/hero/hero-blurred-lg.jpg');
    }

    @supports (filter: blur()) {
      animation-name: blur;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-delay: 2s;
      background-image: url('~@/assets/hero/hero-sm.jpg');

      @include lg-up {
        animation-name: blurlg;
        background-image: url('~@/assets/hero/hero-md.jpg');
      }

      @include xl-up {
        animation-name: blurxl;
        background-image: url('~@/assets/hero/hero-lg.jpg');
      }
    }
  }

  &__inner {
    will-change: opacity;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 310px;

    @supports (filter: blur()) {
      animation: fadein 1s;
      animation-fill-mode: both;
      animation-delay: 2s;
    }

    @include md-up {
      height: 40vw;
      min-height: 480px;
    }

    @include lg-up {
      min-height: 580px;
    }
  }

  &__logo {
    height: auto;

    @include md-up {
      margin-top: 20px;
      width: 160px;
    }

    @include lg-up {
      width: 200px;
    }

    @include xl-up {
      width: 220px;
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 1rem;
    color: $white;
    margin-top: 1rem;
    font-size: 1.85rem;
    max-width: 280px;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    @include font-smoothing;

    @include sm-up {
      font-size: 2.5rem;
      max-width: 500px;
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }

    @include md-up {
      margin-bottom: 3rem;
      max-width: 700px;
      font-size: 3rem;
      text-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }

    @include lg-up {
      max-width: 800px;
      font-size: 4rem;
      text-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    }

    @include xl-up {
      max-width: 1000px;
      font-size: 5rem;
      text-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
