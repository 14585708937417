import Vue from 'vue'
import Router from 'vue-router'

import About from '@/pages/about/About'
import Donate from '@/pages/about/Donate'
import DonationCancelled from '@/pages/about/DonationCancelled'
import DonationSuccess from '@/pages/about/DonationSuccess'
import CalendarSync from '@/pages/CalendarSync'
import Cookies from '@/pages/Cookies'
import FAQ from '@/pages/faq/FAQ'
import Feedback from '@/pages/Feedback'
import NotFound from '@/pages/NotFound'
import Privacy from '@/pages/privacy/Privacy'
import Profile from '@/pages/Profile'
import Terms from '@/pages/terms/Terms'
import Upgrade from '@/pages/upgrade/Upgrade'

// home
import ConfirmInvitation from '@/pages/ConfirmInvitation'
import Home from '@/pages/Home'

// onboarding
import CompleteProfile from '@/pages/onboarding/views/CompleteProfile'
import Intro from '@/pages/onboarding/views/Intro'
import IntroGateway from '@/pages/onboarding/views/IntroGateway'
import UploadAvatar from '@/pages/onboarding/views/UploadAvatar'
import Welcome from '@/pages/onboarding/Welcome'

// auth
import LogIn from '@/pages/auth/LogIn'
import RequestSignInCode from '@/pages/auth/RequestSignInCode'
import VerifySignInCode from '@/pages/auth/VerifySignInCode'
import AppleLogin from '@/pages/auth/views/AppleLogin'
import GoogleLogin from '@/pages/auth/views/GoogleLogin'

// events
import EventAdd from '@/pages/events/EventAdd'
import EventAddSuccess from '@/pages/events/EventAddSuccess'
import EventDetails from '@/pages/events/EventDetails'
import EventEdit from '@/pages/events/EventEdit'
import EventInvitation from '@/pages/events/EventInvitation'
import EventInvitationAuth from '@/pages/events/EventInvitationAuth'
import EventInvitationSuccess from '@/pages/events/EventInvitationSuccess'
import EventAddToCalendar from '@/pages/events/views/AddToCalendar'
import EventDelete from '@/pages/events/views/EventDelete'
import EventInvite from '@/pages/events/views/EventInvite'
import EventLeave from '@/pages/events/views/EventLeave'

// event wizard
import EventWizardSummary from '@/pages/events/views/EventWizardSummary'
import EventWizardWhat from '@/pages/events/views/EventWizardWhat'
import EventWizardWhen from '@/pages/events/views/EventWizardWhen'
import EventWizardWhere from '@/pages/events/views/EventWizardWhere'
import EventWizardWho from '@/pages/events/views/EventWizardWho'

// teams
import TeamDetails from '@/pages/teams/TeamDetails'
import TeamInvitation from '@/pages/teams/TeamInvitation'
import TeamInvitationSuccess from '@/pages/teams/TeamInvitationSuccess'
import TeamOverview from '@/pages/teams/TeamOverview'
import TeamAdd from '@/pages/teams/views/TeamAdd'
import TeamEdit from '@/pages/teams/views/TeamEdit'
import TeamInvite from '@/pages/teams/views/TeamInvite'
import TeamLeave from '@/pages/teams/views/TeamLeave'
import TeamMemberDetails from '@/pages/teams/views/TeamMemberDetails'

import store from '@/store'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    // If the next url is a child of the current url (or the other way around)
    // then keep the scroll position
    if (to.matched[0] === from.matched[0]) return

    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        headerType: function () {
          return store.getters.isAuthenticated || store.getters.isAuthLoading ? '' : 'transparent'
        },
        requiresAuth: function () {
          return store.getters.isAuthenticated || store.getters.isAuthLoading
        }
      }
    },
    {
      path: '/welcome',
      component: Welcome,
      meta: {
        requiresAuth: true,
        headerType: 'none',
        footerType: 'none'
      },
      children: [
        {
          path: 'complete-profile',
          component: CompleteProfile,
          name: 'CompleteProfile'
        },
        {
          path: 'upload-avatar',
          component: UploadAvatar,
          name: 'UploadAvatar'
        },
        {
          path: 'view-intro',
          component: IntroGateway,
          name: 'IntroGateway'
        },
        {
          path: 'intro',
          component: Intro,
          name: 'Intro'
        }
      ]
    },
    {
      path: '/confirm-invitation',
      component: ConfirmInvitation,
      name: 'ConfirmInvitation',
      meta: {
        headerType: 'none',
        footerType: 'none'
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: LogIn,
      meta: {
        headerType: 'none',
        footerType: 'none'
      },
      children: [
        {
          path: 'apple-id',
          component: AppleLogin,
          name: 'AppleLogin',
          alias: '/apple-oauth2-login'
        },
        {
          path: 'google',
          component: GoogleLogin,
          name: 'GoogleLogin',
          alias: '/google-oauth2-login'
        }
      ]
    },
    {
      path: '/login/email',
      name: 'RequestSignInCode',
      component: RequestSignInCode,
      meta: {
        headerType: 'none',
        footerType: 'none'
      }
    },
    {
      path: '/login/email/verify',
      alias: '/verify-otp',
      name: 'VerifySignInCode',
      component: VerifySignInCode,
      meta: {
        headerType: 'none',
        footerType: 'none'
      }
    },
    {
      path: '/event-details',
      redirect: (to) => {
        const { id, ...other } = to.query
        return {
          name: 'EventDetails',
          params: { eventId: id },
          query: other
        }
      }
    },
    {
      path: '/events/add',
      name: 'EventAdd',
      component: EventAdd,
      meta: {
        requiresAuth: true,
        headerType: 'none',
        footerType: 'none'
      },
      children: [
        {
          path: 'who',
          component: EventWizardWho,
          name: 'AddEventWizardWho'
        },
        {
          path: 'what',
          component: EventWizardWhat,
          name: 'AddEventWizardWhat'
        },
        {
          path: 'when',
          component: EventWizardWhen,
          name: 'AddEventWizardWhen'
        },
        {
          path: 'where',
          component: EventWizardWhere,
          name: 'AddEventWizardWhere'
        },
        {
          path: 'summary',
          component: EventWizardSummary,
          name: 'AddEventWizardSummary'
        }
      ]
    },
    {
      path: '/events/add/success',
      name: 'EventAddSuccess',
      component: EventAddSuccess,
      meta: {
        requiresAuth: true,
        headerType: 'none',
        footerType: 'none'
      }
    },
    {
      path: '/events/:eventId',
      name: 'EventDetails',
      component: EventDetails,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'add-to-calendar',
          component: EventAddToCalendar,
          name: 'AddToCalendar'
        },
        {
          path: 'delete',
          component: EventDelete,
          name: 'EventDelete'
        },
        {
          path: 'leave',
          component: EventLeave,
          name: 'EventLeave'
        },
        {
          path: 'invite',
          component: EventInvite,
          name: 'EventInvite'
        }
      ]
    },
    {
      path: '/events/:eventId/edit',
      name: 'EventEdit',
      component: EventEdit,
      meta: {
        requiresAuth: true,
        headerType: 'none',
        footerType: 'none'
      },
      children: [
        {
          path: 'overview',
          component: EventWizardSummary,
          name: 'EditEventWizardOverview'
        },
        {
          path: 'what',
          component: EventWizardWhat,
          name: 'EditEventWizardWhat'
        },
        {
          path: 'when',
          component: EventWizardWhen,
          name: 'EditEventWizardWhen'
        },
        {
          path: 'where',
          component: EventWizardWhere,
          name: 'EditEventWizardWhere'
        }
      ]
    },
    {
      path: '/event-invitation',
      name: 'EventInvitation',
      component: EventInvitation,
      meta: {
        headerType: 'none',
        footerType: 'none'
      }
    },
    {
      path: '/event-invitation/auth',
      name: 'EventInvitationAuth',
      component: EventInvitationAuth,
      meta: {
        headerType: 'none',
        footerType: 'none'
      }
    },
    {
      path: '/event-invitation/success',
      name: 'EventInvitationSuccess',
      component: EventInvitationSuccess,
      meta: {
        headerType: 'none',
        footerType: 'none'
      }
    },
    {
      path: '/teams',
      name: 'TeamOverview',
      component: TeamOverview,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'add',
          component: TeamAdd,
          name: 'TeamAdd'
        }
      ]
    },
    {
      path: '/teams/:teamId',
      name: 'TeamDetails',
      component: TeamDetails,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'edit',
          name: 'TeamEdit',
          component: TeamEdit
        },
        {
          path: 'leave',
          name: 'TeamLeave',
          component: TeamLeave
        },
        {
          path: 'invite',
          name: 'TeamInvite',
          component: TeamInvite
        },
        {
          path: 'members/:userId',
          name: 'TeamMemberDetails',
          component: TeamMemberDetails
        }
      ]
    },
    {
      path: '/invitation',
      name: 'TeamInvitation',
      component: TeamInvitation,
      meta: {
        headerType: 'none',
        footerType: 'none'
      }
    },
    {
      path: '/invitation/success',
      name: 'TeamInvitationSuccess',
      component: TeamInvitationSuccess,
      meta: {
        requiresAuth: true,
        headerType: 'none',
        footerType: 'none'
      }
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/calendar-sync',
      name: 'CalendarSync',
      component: CalendarSync,
      meta: {
        requiresAuth: true,
        headerType: 'none',
        footerType: 'none'
      }
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/upgrade',
      name: 'Upgrade',
      component: Upgrade
    },
    {
      path: '/donate',
      name: 'Donate',
      component: Donate,
      children: [
        {
          path: 'donation-success',
          component: DonationSuccess,
          name: 'DonationSuccess'
        },
        {
          path: 'donation-cancelled',
          component: DonationCancelled,
          name: 'DonationCancelled'
        }
      ]
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: FAQ
    },
    {
      path: '/feedback',
      name: 'Feedback',
      component: Feedback,
      meta: {
        headerType: 'none',
        footerType: 'none'
      }
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy
    },
    {
      path: '/terms',
      name: 'Terms',
      component: Terms
    },
    {
      path: '/cookies',
      name: 'Cookies',
      component: Cookies
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound,
      meta: {
        headerType: 'inverted',
        footerType: 'none'
      }
    }
  ]
})
