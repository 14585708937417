<template>
  <div class="nutshell">
    <div class="container">
      <div class="nutshell__steps">
        <div class="nutshell__step">
          <div class="nutshell__icon-container">
            <icon :icon="['fal', 'calendar-plus']" class="nutshell__icon" />
          </div>
          <div class="nutshell__text-container">
            <h3 class="nutshell__title">{{ $t('home.nutshell.step1.title') }}</h3>
            <div class="nutshell__text">{{ $t('home.nutshell.step1.text') }}</div>
          </div>
        </div>
        <div class="nutshell__step">
          <div class="nutshell__icon-container">
            <icon :icon="['fal', 'users']" class="nutshell__icon" />
          </div>
          <div class="nutshell__text-container">
            <h3 class="nutshell__title">{{ $t('home.nutshell.step2.title') }}</h3>
            <div class="nutshell__text">{{ $t('home.nutshell.step2.text') }}</div>
          </div>
        </div>
        <div class="nutshell__step">
          <div class="nutshell__icon-container">
            <icon :icon="['fal', 'check-circle']" class="nutshell__icon" />
          </div>
          <div class="nutshell__text-container">
            <h3 class="nutshell__title">{{ $t('home.nutshell.step3.title') }}</h3>
            <div class="nutshell__text">{{ $t('home.nutshell.step3.text') }}</div>
          </div>
        </div>
      </div>
      <router-link class="btn btn-sm-lg btn-secondary mt-3" :to="{ name: 'Login' }">{{
        $t('home.nutshell.get_started')
      }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss">
.nutshell {
  background-color: $white;
  background-position: right bottom;
  background-repeat: no-repeat;
  padding: 30px 0;

  @include sm-up {
    padding: 50px 0;
  }

  @include md-up {
    padding: 100px 0;
    display: flex;

    @include img-retina(
      '~@/assets/en/nutshell/nutshell-md.jpg',
      '~@/assets/en/nutshell/nutshell-md@2x.jpg',
      402px,
      auto
    );

    [lang='nl'] & {
      @include img-retina(
        '~@/assets/nl/nutshell/nutshell-md.jpg',
        '~@/assets/nl/nutshell/nutshell-md@2x.jpg',
        402px,
        auto
      );
    }
  }

  @include lg-up {
    padding: 150px 0;
    background-position: calc(50% + 300px) bottom;

    @include img-retina(
      '~@/assets/en/nutshell/nutshell-lg.jpg',
      '~@/assets/en/nutshell/nutshell-lg@2x.jpg',
      650px,
      auto
    );

    [lang='nl'] & {
      @include img-retina(
        '~@/assets/nl/nutshell/nutshell-lg.jpg',
        '~@/assets/nl/nutshell/nutshell-lg@2x.jpg',
        650px,
        auto
      );
    }
  }

  @include xl-up {
    background-position: calc(50% + 700px) bottom;

    @include img-retina(
      '~@/assets/en/nutshell/nutshell-xl.jpg',
      '~@/assets/en/nutshell/nutshell-xl@2x.jpg',
      1341px,
      auto
    );

    [lang='nl'] & {
      @include img-retina(
        '~@/assets/nl/nutshell/nutshell-xl.jpg',
        '~@/assets/nl/nutshell/nutshell-xl@2x.jpg',
        1341px,
        auto
      );
    }
  }

  &__steps {
    margin-bottom: 20px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 20px;
      bottom: 20px;
      width: 3px;
      left: 18px;
      background-color: $gray-200;
      @include sm-up {
        left: 31px;
      }
    }

    @include md-up {
      margin-bottom: 30px;
    }

    @include lg-up {
      margin-bottom: 50px;
    }
  }

  &__step {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;

    @include sm-up {
      margin-bottom: 2rem;
    }

    @include md-up {
      margin-bottom: 3rem;
    }
  }

  &__title {
    margin: 0;
    @include xs-only {
      font-size: 1.2rem;
    }
  }

  &__text {
    color: $text-muted;

    @include md-up {
      max-width: 300px;
    }

    @include xs-only {
      font-size: 12px;
    }
  }

  &__icon-container {
    margin-right: 1rem;
    line-height: 39px;
    flex: 0 0 39px;
    text-align: center;
    border-radius: 50%;
    color: $white;
    background-color: $primary;
    font-size: 20px;
    box-shadow: 0 0 0 4px $white;

    @include sm-up {
      box-shadow: 0 0 0 5px $white;
      margin-right: 2rem;
      flex: 0 0 65px;
      line-height: 65px;
      font-size: 30px;
    }
  }
}
</style>
