export default {
  en: {
    hero: {
      text: 'The easiest way to do things together.',
      sign_up: 'Sign up for free',
      log_in: 'Log in'
    },
    nutshell: {
      step1: {
        title: 'Organize an activity',
        text: 'Weekly practice? Family BBQ? Spontaneous dinner party? Yes to all.'
      },
      step2: {
        title: 'Invite everyone',
        text: 'Share activity details automatically with group members and via a link with everyone else.'
      },
      step3: {
        title: "See who's coming",
        text: "Invitees show whether they're coming. Excuses are optional."
      },
      get_started: 'Get started'
    },
    download_app: {
      title: 'Get the app',
      text: "It's built for speed<br>and ease of use."
    }
  },
  nl: {
    hero: {
      text: 'De makkelijkste manier om samen dingen te doen.',
      sign_up: 'Gratis account aanmaken',
      log_in: 'Inloggen'
    },
    nutshell: {
      step1: {
        title: 'Plan een activiteit in',
        text: 'Wekelijkse training? Familiedag? Pubquiz? Gooi er maar in.'
      },
      step2: {
        title: 'Nodig iedereen uit',
        text: 'Nodig automatisch je hele groep uit of deel een linkje via WhatsApp.'
      },
      step3: {
        title: 'Erbij of niet?',
        text: 'Iedereen laat weten of ze kunnen. Smoesjes zijn optioneel.'
      },
      get_started: 'Aan de slag'
    },
    download_app: {
      title: 'De Erbij-app',
      text: 'Gebouwd voor snelheid<br>en gebruiksgemak.'
    }
  }
}
