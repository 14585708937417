<template>
  <div class="home">
    <hero />
    <nutshell />
    <download-app />
  </div>
</template>

<script>
import DownloadApp from './views/DownloadApp'
import Hero from './views/Hero'
import Nutshell from './views/Nutshell'

export default {
  name: 'Homepage',
  components: {
    Hero,
    Nutshell,
    DownloadApp
  }
}
</script>
